<template>
  
  <Vue3DraggableResizable
    :id="'box' + id" 
    :class="((objType === 'fullSignature' && signSrc !== null) || (objType === 'initial' && iniSrc !== null) ? 'boxSignInitial ' : (objType === 'fullSignature' || objType === 'initial' ? 'boxSignInitialDef ' : 'boxDisabled ')) + boxClass + 'Bg'"
    :initW="w"
    :initH="h"
    v-model:x="x"
    v-model:y="y" 
    v-model:w="w"
    v-model:h="h"
    :disabledX="true"
    :disabledY="true"
    :parent="true"
    :draggable="true"
    :resizable="objType === 'fullSignature' || objType === 'initial' ? true : false"
    :style="'font-family:' + fontFamily + '; font-size:' + fontSize + 'px'"
    @resizing="resizeEndHandle"
    :lockAspectRatio="objType === 'fullSignature' || objType === 'initial' ? true : false"
    >

    <!-- :class="'boxDisabled ' + boxClass + 'Bg'" -->
    
    <div v-if="objType === 'name'" class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
      <fa icon="user-circle" size="lg" class="me-1" /> 
      <span>Name</span>
    </div>

    <div v-if="objType === 'email'" class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
      <fa icon="user-circle" size="lg" class="me-1" /> 
      <span>Email</span>
    </div>

    <div v-if="objType === 'idPassport'" class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
      <fa icon="user-circle" size="lg" class="me-1" /> 
      <span>ID/Passport</span>
    </div>

    <div v-if="objType === 'textbox'" class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
      <fa icon="font" size="lg" class="me-1" /> 
      <span>Textbox</span>
    </div>

    <div v-if="objType === 'textarea'" class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
      <fa icon="bars" size="lg" class="me-1" /> 
      <span>Textarea</span>
    </div>

    <div v-if="objType === 'checkbox'" class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
      <fa icon="check-square" size="lg" class="me-1" /> 
      <span>Checkbox</span>
    </div>

    <div v-if="objType === 'dateSigned'" class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="cursor: pointer">
      <fa icon="calendar-alt" size="lg" class="me-1" />
      <span>Date Signed</span>
      <!-- <Datepicker :modelValue="date" @update:modelValue="setDate" :enableTimePicker="false" position="left" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" class="boxCal" autoApply></Datepicker> -->
    </div>

    <div v-if="objType === 'initial'">
      <div v-if="iniSrc !== null" class="text-center">
        <img :src="'data:image/png;base64, ' + iniSrc" class="rounded" :style="'opacity: 0.8; object-fit: scale-down; width: ' + (iniW === 0 ? iWidth : iniW) + 'px; height: ' + (iniH === 0 ? iHeight : iniH) + 'px; max-width:' + signIniMaxW + '; max-height: ' + signIniMaxH">
      </div>
      <div class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
        <fa icon="pen-nib" size="lg" class="me-1" style="cursor: pointer" />
        <span style="cursor: pointer">Initial</span>
        <!-- <span>Initial - i:{{iWidth}}x{{iHeight}}, ini:{{iniW}}x{{iniH}} | {{iniRatio}}</span> -->
      </div>
    </div>

    <div v-if="objType === 'fullSignature'" >
      <div v-if="signSrc !== null" class="text-center">
        <img v-if="signSrc" :src="'data:image/png;base64, ' + signSrc" class="rounded" :style="'opacity: 0.8; object-fit: scale-down; width:' + (signW === 0 ? sWidth : signW) + 'px; height: ' + (signH === 0 ? sHeight : signH) + 'px; max-width:' + signIniMaxW + '; max-height: ' + signIniMaxH">
      </div>
      <div class="p-1" data-bs-toggle="modal" :data-bs-target="'#' + mdl" style="position:absolute; top: 0px; left: 0px; z-index: 1">
        <fa icon="signature" size="lg" class="me-1" style="cursor: pointer" /> 
        <span style="cursor: pointer">Full Signature</span>
        <!-- <span>Full Signature - s:{{sWidth}}x{{sHeight}}, sign:{{signW}}x{{signH}} | {{signRatio}}</span> -->
      </div>
    </div>


    <!-- Temporary hide date picker feature
    <div v-if="title === 'Date Signed'" @click="openAttr()" class="p-1">
      <fa icon="calendar-alt" size="lg" class="me-1" />
      <span>{{title}}</span>
      <Datepicker :modelValue="date" @update:modelValue="setDate" :enableTimePicker="false" position="left" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" class="boxCal" autoApply></Datepicker>
    </div> -->


    <!-- Temporary hide click to edit feature 
    <div v-if="title === 'Textbox' && editable[id]" class="p-1">
      <table>
        <tr>
          <td><fa icon="font" size="lg" class="me-1" /></td>
          <td><input v-if="editable[id]" type="text" v-model="label" class="form-control form-control-sm" style="margin: 0px 8px; width: 150px" @blur="toggleEdit(id)" /></td>
        </tr>
      </table>
    </div>

    <div v-if="title === 'Textbox' && !editable[id]" class="p-1">
      <fa icon="font" size="lg" class="me-1" />
      <span @click="toggleEdit(id)">{{ label }}</span>
    </div> -->

    <!-- <div class="text-primary"> id:box{{ id }} - {{x}}x{{y}} pg{{page}} sg{{signee}}<br>{{cat}} | {{fontFamily}}={{ fontSize }}</div> -->
    <!-- <div class="text-primary">{{x}}, {{y}}</div> -->
    <!-- <div class="text-primary">{{w}} x {{h}} </div> -->
    <!-- <div class="text-primary">W: {{w}}, H:{{h}} </div> -->
    <!-- <div class="text-primary">{{viewWidth}}</div> -->

  </Vue3DraggableResizable>

</template>
 
<script>
import { ref, watch, onMounted, inject } from 'vue'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

export default {
    props: [ 'data', 'boxClass', 'mdl', 'signSrc', 'iniSrc', 'sWidth', 'sHeight', 'iWidth', 'iHeight', 'signRatio', 'iniRatio', 'viewWidth'],
    components: { Vue3DraggableResizable, Datepicker },
    emits: ['getResize'],
    setup (props, { emit }) {

        const signIniMaxW = ref(250)
        const signIniMaxH = ref(150)

        const x = ref(props.data.position.x)
        const y = ref(props.data.position.y)
        const w = ref(200)
        const h = ref(32)
        const signee = ref(props.signee)
        const label = ref(props.data.label)
        const placeHolder = ref(props.data.placeholder)
        const mandatory = ref(props.data.isMandatory)
        const page = ref(props.data.position.page)
        const objType = ref(props.data.type)
        const fontFamily = ref(props.data.format.font)
        const fontSize = ref(props.data.format.size)
        const boxClass = ref(props.boxClass)
        const mdlId = ref(props.mdl)
        const viewWidth = ref(props.viewWidth)

        // Signature preview
        // const signSrc = ref(props.signSrc)

        // const signatureSrc = ref(props.signSrc)
        const signW = ref(props.sWidth)
        const signH = ref(props.sHeight)
        const iniW = ref(props.iWidth)
        const iniH = ref(props.iHeight)
        
        // console.info('mdlId', mdlId.value, 'props.mdl', props.mdl)
        // console.info('mdlId', signatureSrc.value, 'props.iniSrc', props.iniSrc)
        /**
         * Applicable to potrait & landscape, adjust bigger & smaller
         * Img ratio = Orig Width / Orig Height
         * 
         * New Height = New Width / Img Ratio
         * New Width = New Height * Img ratio
         * 
         */

        // Move to onmounted
        if (objType.value === 'fullSignature' || objType.value === 'initial') {
          w.value = 300
          h.value = 200
          // console.info('signSrc', props.signSrc)
        }

        if (fontFamily.value === undefined) {
          fontFamily.value = 'arial'
        }

        if (fontSize.value === undefined) {
          fontSize.value = 14
        }

        // console.info('Data', JSON.stringify(props.data))
        // console.info('DragBox def', props.boxClass)
        // console.info('w x h', w.value, h.value)

        const id = ref(props.data.id)
        const editable = ref([])

        // const date = ref(new Date())
        // const date = ref(label.value)

        const mdlBoxId = ref(null)

        const toggleEdit = (id) => {
          editable.value[id] = !(editable.value[id] === true ? true : false)
        }

        const nextModal = () => {
          console.info('next modal')
        }

        const resizeEndHandle = async (obj) => {
          // console.info('x: ', x1, 'y: ', y1)
          console.info('--- resize', 'w: ', obj.w, 'h: ', obj.h, obj)
          // console.info('--- resize ---', objType.value, id.value, 'w: ', obj.w, 'h: ', obj.h)

          // console.info('---resizeEndHandle---', objType.value)

          /*
          LIVE: Temp Hide

          if (obj.w > 200) {
            obj.w = 200
            w.value = 200
          }

          if (obj.h > 80) {
            obj.h = 80
            h.value = 80
          }

          emit('getResize', objType.value, id.value, obj.w, obj.h) */
          // if (objType.value === 'fullSignature') {

          // Control signature/initial exceed max limit as allow to do so
          if (obj.w > signIniMaxW.value) {
              console.info('EXCEED W', obj.w)
              obj.w = signIniMaxW.value
              w.value = signIniMaxW.value

              h.value =  w.value/props.signRatio
              props.sWidth = w.value
              props.sHeight = h.value

          } else if (obj.h > signIniMaxH.value) {
              console.info('EXCEED H', obj.h)
              obj.h = signIniMaxH.value
              h.value = signIniMaxH.value

              w.value =  h.value*props.signRatio
              props.sWidth = w.value
              props.sHeight = h.value

          } else {
              console.info('X EXCEED ')
              obj.w = w.value
              obj.h = h.value
              props.sWidth = w.value
              props.sHeight = h.value
          }

          // If resized initial still exceed
          if (obj.h > signIniMaxH.value) {
              console.info('**** H', obj.h, ' > ', signIniMaxH.value)
              obj.h = signIniMaxH.value
              if (objType.value === 'fullSignature') {
                  obj.w = obj.h*props.signRatio
              } else {
                  obj.w = obj.h*props.iniRatio
              }

              props.sWidth = w.value
              props.sHeight = h.value
          }

          if (obj.w > signIniMaxW.value) {
              console.info('**** W', obj.w, ' > ', signIniMaxW.value)
              obj.w = signIniMaxW.value
              if (objType.value === 'fullSignature') {
                  obj.h = obj.w/props.signRatio
              } else {  
                  obj.h = obj.w/props.iniRatio
              }

              props.sWidth = w.value
              props.sHeight = h.value
          }
         

          if (objType.value === 'fullSignature') {
              signW.value = w.value
              signH.value = h.value
          }

          if (objType.value === 'initial') {
              iniW.value = w.value
              iniH.value = h.value
          }

          console.info('------- resize final', 'w: ', obj.w, 'h: ', obj.h, obj)

          emit('getResize', objType.value, id.value, obj.w, obj.h)
        }

        onMounted(() => {
          //  console.info('onMounted', w.value, h.value)
          /* if (objType.value === 'fullSignature' || objType.value === 'initial') {
            h.value = 80

            console.info('onMounted: signSrc', signSrc.value)
            
          } */

          if (viewWidth.value <= 500) {

              if (objType.value === 'fullSignature' || objType.value === 'initial') {

                  w.value = w.value * 0.24
                  h.value = h.value * 0.24

                  signIniMaxW.value = signIniMaxW.value * 0.24
                  signIniMaxH.value = signIniMaxH.value * 0.24

              } else {
                  w.value = w.value * 0.25
                  h.value = h.value * 0.25
              }
          }
        })

        return { 
          x, y, id, w, h, signee, label, page, placeHolder, mandatory, objType, boxClass, 
          fontFamily, fontSize, toggleEdit, editable, mdlBoxId, nextModal,
          resizeEndHandle, mdlId,
          signW, signH, iniW, iniH, signIniMaxW, signIniMaxH, viewWidth
          }
    }
}
</script>

<style>

/* .box {
    border-style: dashed;
    border-width: 3px;
    cursor: pointer;
    border-radius: 5px;
}
.box:hover {
    border-style: dashed;
    border-width: 3px;
    cursor: move;
}
.box:active {
    cursor: move;
    border-style: dashed;
    border-width: 3px;
}
.boxSelected {
    border-width: 4px;
    border-style: solid;
}
.boxCal {
    padding-left: 8px;
    padding-right: 8px;
    width: 200px;
}
.color0 {
    color: #1141AF;
    border-color: #1141AF;
}
.color0Bg {
    color: #1141AF;
    border-color: #1141AF;
    background-color: rgba(17, 65, 175, 0.2);
} */
</style>